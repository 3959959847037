<template>
  <div class="position-relative side-alert">
    <div class="position-absolute overflow-auto w-100 h-100 bg-white">
      <el-tabs v-model="activeName" class="p-5">
        <el-tab-pane label="ToDo Task" name="today">
          <div class="position-relative rounded-lg mt-3 bg-warning text-white p-3">
            <small>High Priority</small>
            <h5 class="font-weight-bold m-0">Buy</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
          <div class="position-relative rounded-lg mt-3 bg-info text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Go to the store</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
        <el-tab-pane label="Done Task" name="done">
          <div class="position-relative rounded-lg mt-3 bg-success text-white p-3">
            <small>Normal Priority</small>
            <h5 class="font-weight-bold m-0">Pay at the bank</h5>
            <p class="mb-0">brief description of the activity</p>
            <el-radio v-model="state" class="position-absolute todo-state"></el-radio>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="position-absolute w-100 text-center new-task">
        <a class="btn btn-primary text-white rounded-pill btn-big" @click="drawer = true">Add New</a>
      </div>
    </div>

    <el-drawer title="I am the title" :visible.sync="drawer" direction="rtl" size="400px">
      <div class="pt-3 pl-5 pr-5 pb-5">
        <el-form :model="form" ref="form">
          <el-form-item label="Title" prop="name">
            <el-input type="text" v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="Detail" prop="email">
            <el-input type="textarea" v-model="form.email" :rows="5"></el-input>
          </el-form-item>          
          <el-button type="primary" @click="submitForm('form')">Save</el-button>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: "today",
      state: 0,
      drawer: false,
      form: {
        id: null,
        name: null,
        email: null,
        phone: null,
        discriminator: null,
        office: null
      }
    };
  }
};
</script>

<style lang="scss">
.todo-state {
  top: 15px;
  right: 5px;
}
.new-task {
  bottom: 20px;
  margin: auto;
}
.btn-big {
  line-height: 1.5;
  letter-spacing: 0.05rem;
  padding: 10px 40px;
}
</style>